import logo from './logo.svg';
import './App.css';

function App() {
  return (
      <div className="App">
      <title>JKG Abi24</title>
          <body className="App-body">
              <iframe title="Google Form embed" className="App-form" src="https://docs.google.com/forms/d/e/1FAIpQLSeFEiEYmOXUuSGON2K1n7mD49k7YUL5yrETn6RBHZ4S7nOqeA/viewform?embedded=true" width="640" height="1031" frameborder="0" marginheight="0" marginwidth="0">Wird geladen�</iframe>
          </body>
          <footer className="App-footer">
              <p>Diese Webseite steht in keiner Weise mit dem JKG in Verbindung.</p>
              <p><a href="mailto:kontakt@abi24-jkg.de">Kontakt</a></p>
        </footer>
    </div>
  );
}

export default App;
